module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-1G4RJVZT1G"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"トシキワタナベのブログ","short_name":"トシキワタナベのブログ","start_url":"/","theme_color":"#b6b8c3","background_color":"#e8eaf6","display":"minimal-ui","icon":"static/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bd6adbce9563ed6d9558ccb5671e4dd8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"remarkPlugins":[null],"rehypePlugins":[[null,{"strict":"ignore"}]],"gatsbyRemarkPlugins":[{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-table-of-contents","id":"7828d00f-6e11-5b48-b367-b6103ccd24a0","name":"gatsby-remark-table-of-contents","version":"2.0.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-table-of-contents/dist/gatsby-remark-table-of-contents.cjs.js","pluginOptions":{"plugins":[],"tight":false,"ordered":false,"fromHeading":2,"toHeading":3,"className":"table-of-contents"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-autolink-headers","id":"5972ab46-b528-5d9e-90bd-1a8eec5af8a9","name":"gatsby-remark-autolink-headers","version":"5.11.1","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"icon":false,"maintainCase":false,"elements":["h2","h3","h4"]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-prismjs","id":"5023d14b-37aa-5851-92fb-c14b1e804e35","name":"gatsby-remark-prismjs","version":"6.11.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"noInlineHighlight":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-custom-blocks","id":"f38a1c80-e5c8-552f-99cf-8502208b4e24","name":"gatsby-remark-custom-blocks","version":"4.11.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-custom-blocks/index.js","module":{},"pluginOptions":{"plugins":[],"blocks":{"digression":{"classes":"digression"}}},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"maintainCase":false,"elements":["h2","h3","h4"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
